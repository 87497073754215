
import Vue from "vue";
import { devices, IDevice } from "@/api";
import DashboardSupport from "@/components/common/dashboard/DashboardSupport.vue";
import { Actions } from "@/store/models";
import UploadHistory from "./UploadHistory.vue";
import BackButton from "@/components/common/BackButton.vue";

export default Vue.extend({
  name: "GroupProfile",
  components: {
    DashboardSupport,
    UploadHistory,
    BackButton,
  },
  data() {
    return {
      item: {} as IDevice,
      supportHours: process.env.VUE_APP_SUPPORT_HOURS,
    };
  },
  computed: {
    id: function (): number {
      return Number(this.$route.params.id);
    },
    details: function (): any {
      const { cao } = this.$store.state.company?.account || {};
      return [
        {
          label: "Serial Number",
          text: this.item.device_serial_number?.split("_")?.[1],
        },
      ];
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    async getItem() {
      try {
        this.item = (await devices.get(this.id)).data[0];
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    }
  },
});


import { devices, IProjectByDevice } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "UploadHistory",
  props: {
    item: Object,
  },
  data() {
    return {
      projects: [] as IProjectByDevice[],
    };
  },
  computed: {},
  created() {
    this.getProjectsByDevice();
  },
  methods: {
    async getProjectsByDevice() {
      try {
        this.projects = (await devices.getProjectsByDevice(this.item.id)).data;
        // this.projects = [
        //   {
        //     id: 14,
        //     name: "sampl_prj1.prj",

        //     size: 21493561,
        //     status: 1,
        //     created_by: "user1@abc.com",
        //     created_datetime: "2023-08-08T18:08:33.000Z",

        //     company: {
        //       id: 2,
        //       name: "ABC",
        //       account_number: 2,
        //     },
        //   },
        //   {
        //     id: 11,
        //     name: "sampl_prj1.prj",

        //     size: 21493561,
        //     status: 1,
        //     created_by: "user1@abc.com",
        //     created_datetime: "2023-08-08T18:08:33.000Z",

        //     company: {
        //       id: 2,
        //       name: "ABC",
        //       account_number: 2,
        //     },
        //   },
        //   {
        //     id: 15,
        //     name: "sampl_prj1.prj",

        //     size: 21493561,
        //     status: 1,
        //     created_by: "user1@abc.com",
        //     created_datetime: "2023-08-08T18:08:33.000Z",

        //     company: {
        //       id: 2,
        //       name: "ABC",
        //       account_number: 2,
        //     },
        //   },
        //   {
        //     id: 16,
        //     name: "sampl_prj1.prj",

        //     size: 21493561,
        //     status: 1,
        //     created_by: "user1@abc.com",
        //     created_datetime: "2023-08-08T18:08:33.000Z",

        //     company: {
        //       id: 2,
        //       name: "ABC",
        //       account_number: 2,
        //     },
        //   },
        //   {
        //     id: 17,
        //     name: "sampl_prj1.prj",

        //     size: 21493561,
        //     status: 1,
        //     created_by: "user1@abc.com",
        //     created_datetime: "2023-08-08T18:08:33.000Z",

        //     company: {
        //       id: 2,
        //       name: "ABC",
        //       account_number: 2,
        //     },
        //   },
        // ];
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    goto(id: number) {
      this.$router.push(`/projects/${id}`);
    },
    getTime(p: IProjectByDevice) {
      const date: any = new Date(p.updated_datetime || p.created_datetime);
      const seconds = Math.floor(((new Date() as any) - date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        const amount = Math.floor(interval);
        const unit = amount === 1 ? " year" : " years";
        return amount + unit;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        const amount = Math.floor(interval);
        const unit = amount === 1 ? " month" : " months";
        return amount + unit;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        const amount = Math.floor(interval);
        const unit = amount === 1 ? " day" : " days";
        return amount + unit;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        const amount = Math.floor(interval);
        const unit = amount === 1 ? " hour" : " hours";
        return amount + unit;
      }
      interval = seconds / 60;
      if (interval > 1) {
        const amount = Math.floor(interval);
        const unit = amount === 1 ? " minute" : " minutes";
        return amount + unit;
      }

      const amount = Math.floor(seconds);
      const unit = amount === 1 ? " second" : " seconds";
      return amount + unit;
    },
  },
});
